<template>
       <!-- <div class="toggle-handle col-md-3 text-right" v-if="btnhide !== true">
             <div class="tgh-space"></div>
                    <span v-if="this.mode == 'head'" class="fade" >
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>

                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === true" icon="chevron-up"></b-icon>
                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === false" icon="chevron-down"></b-icon>

                        <IconSet v-if="this.btnicon !== '' && this.btniconclose === undefined" :icon="this.btnicon"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === true" :icon="this.btniconclose"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === false" :icon="this.btnicon"></IconSet>

                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>
                    </span>
                    <a v-else :aria-label="this.get_toggle_btn_aria" href="#" @click="toggle_content()" v-bind:aria-expanded="this.toggleOpen" class="pr-2">
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>

                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === true" icon="chevron-up"></b-icon>
                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === false" icon="chevron-down"></b-icon>

                        <IconSet v-if="this.btnicon !== '' && this.btniconclose === undefined" :icon="this.btnicon"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === true" :icon="this.btniconclose"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === false" :icon="this.btnicon"></IconSet>

                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>
                    </a>
                </div> -->
                <div class="custom-toggle-header">
                        <slot name="title" />
               </div>
               
</template>

<script>
// import IconSet from '@/structure/IconSet.vue'
import { vcm } from '@/mixins/vcm'

export default {
    mixins: [vcm],
    name: 'ToggleTitle',
    components: {
        // IconSet
    },
    props: {
        mode: String,
        id: String,
        open: Boolean,
        group: String,
        btnlabel: String,
        btnlabelclose: String,
        css: String,
        btnicon: String,
        btniconclose: String,
        activateinfo: Boolean,
        btnlabelright: Boolean,
        btnhide: Boolean
    },
    data() {
        return {
            toggleOpen: this.open,
            isOpen : false, 
            labels: {
                Open: this.get_translation('App','Open','label'),
                Close: this.get_translation('App','Close','label'),
            }
        };
    },
    mounted() {
        this.$root.$on('com_event_toggle_open_' + this.group, (eventID) => {
            if (eventID !== this.id) {
                this.toggle_content_set(false)
            }
        })
    },
    computed: {
        get_class: function(){
            var rv = ' ' + this.mode + ' ' + this.css + ' '
            if (this.toggleOpen) {
                rv += ' open '
            }
            return rv
        },
        get_toggle_btn_aria: function(){
            if (this.toggleOpen) {
                return this.labels.Close
            } else {
                return this.labels.Open
            }
        },
    },
    methods: {
        toggle_content(){
            if (this.toggleOpen) {
                this.toggleOpen = false
            } else {
                this.toggleOpen = true
                this.$root.$emit('com_event_toggle_open_' + this.group,this.id)
            }
        },
        toggle_content_set(setState){
            this.toggleOpen = setState
        },
    }
}
</script>


<style scoped >

.fade { visibility: hidden; }
.custom-toggle-header {
    width: 100%;
    background-color: #dfeaf0;
}
.toggle-content.mobileFilter {
    border-top: 0px !important;
}
</style>
