<template>
  <Page>
    <ViewTitle :title="labels.AccountStatement" />
    <h2 class="display-none-heading">{{ labels.AccountStatement }}</h2>
    <div v-if="isMobile">
      <ToggleMobile
        mode="line"
        :id="'filter_section'"
        group="filter"
        :btnlabel="labels.ShowFilter"
        :btnlabelclose="labels.HideFilter"
        css="filter mb-4"
        ref="filter_toggle"
        activateinfo
        :allIsOpen="allIsOpen"
      >
        <template v-slot:title>
          <div
            class="filter-header-text"
            :class="{ onHoverColText: isMobileStreet === true }"
          >
            <IconSet
              icon="house"
              :class="{ onHoverColIcon: isMobileStreet === true }"
            >
            </IconSet>
            <span>{{ labels.Addresses }}</span>
          </div>
        </template>
        <template v-slot:content>
          <div class="container1">
            <div class="filterRow">
              <div class="vcs-column col-md-3 address group line highlight">
                <div class="vcs-check-filter">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="selectedItem"
                      :aria-describedby="ariaDescribedby"
                      name="address"
                    >
                      <div v-for="(address, index) in addressList" :key="index">
                        <b-form-checkbox :value="address">
                          <div class="vcf-emblem">
                            <img
                              :src="
                                address.Source.Emblem === ''
                                  ? get_asset_image('emblems/villach.png')
                                  : get_asset_image(
                                      `emblems/${address.Source.Emblem}`
                                    )
                              "
                              :alt="address.City"
                            />
                          </div>
                          <div class="vcf-title">
                            <span
                              class="cft-name"
                              v-if="address.Street || address.StreetNumber"
                              >{{ address.Street }}
                              {{ address.StreetNumber }}</span
                            >
                            <span
                              class="cft-type"
                              v-if="address.PostalCode || address.City"
                              >{{ address.PostalCode }},{{ address.City }}</span
                            >
                          </div>
                        </b-form-checkbox>
                      </div>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ToggleMobile>

      <ToggleMobile :allIsOpen="allIsOpen">
        <template v-slot:title>
          <div
            class="filter-header-text"
            :class="{ onHoverColText: isMobileSerivceType == true }"
          >
            <IconSet
              :class="{ onHoverColIcon: isMobileSerivceType == true }"
              icon="list"
            ></IconSet
            ><span>{{ labels.Groups }}</span>
          </div>
        </template>
        <template v-slot:content>
          <div class="container1">
            <div class="filterRow">
              <div class="vcs-column col-md-3  group line highlight">
                <div class="vcs-check-filter">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="selectedItem"
                      :aria-describedby="ariaDescribedby"
                      name="serviceType"
                    >
                      <b-form-checkbox
                        v-for="(serviceType, index) in serviceGroupCollection"
                        :key="index"
                        :value="serviceType"
                      >
                        {{ serviceType.Name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ToggleMobile>

      <ToggleMobile :allIsOpen="allIsOpen">
        <template v-slot:title>
          <div
            class="filter-header-text"
            :class="{ onHoverColText: isMobileDate == true }"
          >
            <IconSet
              :class="{ onHoverColIcon: isMobileDate == true }"
              icon="calendar"
            ></IconSet
            ><span>{{ labels.Timespan }}</span>
          </div>
        </template>
        <template v-slot:content>
          <div class="container1">
            <div class="filterRow">
              <div class="vcs-column  group timespan line highlight">
                <div class="vcs-check-filter">
                  <div class="vcf-title input-set">
                    <span class="cft-title">{{ labels.From }}</span>
                  </div>
                  <div class="vcf-input">
                    <b-form-datepicker
                      class="vcs-date-box"
                      size="sm"
                      :max="maxDate"
                      :locale="get_language()"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="fromDate"
                    ></b-form-datepicker>
                  </div>
                </div>
                <div class="vcs-check-filter">
                  <div class="vcf-title input-set">
                    <span class="cft-title">{{ labels.To }}</span>
                  </div>
                  <div class="vcf-input">
                    <b-form-datepicker
                      class="vcs-date-box"
                      size="sm"
                      :min="minDate"
                      :locale="get_language()"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="toDate"
                    ></b-form-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ToggleMobile>

      <ToggleFilterButton>
        <template v-slot:content>
          <div class="container1">
            <div class="filterRow">
              <div class="vcs-column filter-section pb-2">
                <div class="vcs-filter-section">
                  <span v-for="(item, index) in selectedItem" :key="index">
                    <span class="vfi-link" v-if="!('AddressId' in item)"
                      ><span
                        class="fil-mark"
                        @click="removeSelectedItem(item, index)"
                        >x</span
                      >&nbsp;{{
                        item.Name
                          ? item.Name
                          : `${get_formatted_date(
                              item.from,
                              "DD.MM.YYYY"
                            )} - ${get_formatted_date(item.to, "DD.MM.YYYY")}`
                      }}</span
                    >
                    <span class="vfi-link" v-if="'AddressId' in item">
                      <div>
                        <span
                          class="fil-mark"
                          @click="removeSelectedItem(item, index)"
                          >x</span
                        >
                        <span
                          class="vfi-type"
                          v-if="
                            item.AddressId && (item.Street || item.StreetNumber)
                          "
                          ><img
                            :src="
                              item.Source.Emblem === ''
                                ? get_asset_image('emblems/villach.png')
                                : get_asset_image(
                                    `emblems/${item.Source.Emblem}`
                                  )
                            "
                            :alt="item.Source.Emblem"
                        /></span>
                        <span
                          class="vfi-type"
                          v-if="
                            item.AddressId && (item.Street || item.StreetNumber)
                          "
                          >{{ item.Street }}, {{ item.StreetNumber }}</span
                        >
                      </div>
                      <div
                        class="vfi-address"
                        v-if="item.AddressId && (item.PostalCode || item.City)"
                      >
                        {{ item.PostalCode }},{{ item.City }}
                      </div>
                    </span>
                  </span>
                </div>

                <div>
                  <div
                    class="vcs-column address text-center pb-2 ClearButton"
                    @click="clearFilter"
                  >
                    <IconSet icon="filter"></IconSet>{{ labels.RemoveFilter }}
                  </div>
                  <!-- <b-button
                    variant="primary"
                    class="w-100 p-2 rounded vgs-form-button filter-button"
                    @click="onFilterHandler"
                    >{{ labels.FilterBtnText }}
                  </b-button> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </ToggleFilterButton>
    </div>
    <div v-else>
      <Toggle
        mode="filter-line"
        :id="'filter_section'"
        group="filter"
        :btnlabel="labels.ShowFilter"
        :btnlabelclose="labels.HideFilter"
        css="filter mb-4"
        ref="filter_toggle"
        activateinfo
      >
        <template v-slot:title>
          <div class="container1 ">
            <div class="row">
              <div
                class="vcs-column address col-md-4 line"
                :class="{ onHoverColText: isHover === 'home' }"
              >
                <IconSet
                  :class="{ onHoverColIcon: isHover === 'home' }"
                  icon="house"
                ></IconSet
                >{{ labels.Addresses }}
              </div>
              <div
                class="vcs-column group  col-md-4 line"
                :class="{ onHoverColText: isHover === 'list' }"
              >
                <IconSet
                  :class="{ onHoverColIcon: isHover === 'list' }"
                  icon="list"
                ></IconSet
                >{{ labels.Groups }}
              </div>
              <div
                class="vcs-column timespan col-md-4 line"
                :class="{ onHoverColText: isHover === 'calendar' }"
              >
                <IconSet
                  :class="{ onHoverColIcon: isHover === 'calendar' }"
                  icon="calendar"
                ></IconSet
                >{{ labels.Timespan }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <div class="container1">
            <div class="filterRow">
              <div
                class="vcs-column col-md-3 address line highlight"
                @mouseover="isHover = 'home'"
                @mouseleave="isHover = ''"
              >
                <div class="vcs-check-filter">
                  <b-form-group v-slot="{ ariaDescribedby }" label=" ">
                    <b-form-checkbox-group
                      v-model="selectedItem"
                      value-field="address"
                      text-field="name"
                      :aria-describedby="ariaDescribedby"
                      name="address"
                      label=" "
                    >
                      <div v-for="(address, index) in addressList" :key="index">
                        <b-form-checkbox :value="address" label=" ">
                          <div class="vcf-emblem">
                            <img
                              :src="
                                address.Source.Emblem === ''
                                  ? get_asset_image('emblems/villach.png')
                                  : get_asset_image(
                                      `emblems/${address.Source.Emblem}`
                                    )
                              "
                              :alt="address.Source.Emblem"
                            />
                          </div>
                          <div class="vcf-title">
                            <span
                              class="cft-name"
                              v-if="address.Street || address.StreetNumber"
                              >{{ address.Street }}
                              {{ address.StreetNumber }}</span
                            >
                            <span
                              class="cft-type"
                              v-if="address.PostalCode || address.City"
                              >{{ address.PostalCode }},{{ address.City }}</span
                            >
                          </div>
                        </b-form-checkbox>
                      </div>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>

              <div
                class="vcs-column col-md-3  group line highlight"
                @mouseover="isHover = 'list'"
                @mouseleave="isHover = ''"
              >
                <div class="vcs-check-filter">
                  <b-form-group v-slot="{ ariaDescribedby }" label=" ">
                    <b-form-checkbox-group
                      v-model="selectedItem"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox
                        v-for="(serviceType, index) in serviceGroupCollection"
                        :key="index"
                        :value="serviceType"
                      >
                        {{ serviceType.Name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div
                class="vcs-column col-md-3  timespan line highlight"
                @mouseover="isHover = 'calendar'"
                @mouseleave="isHover = ''"
              >
                <div class="vcs-check-filter">
                  <div class="vcf-title input-set">
                    <span class="cft-title">{{ labels.From }}</span>
                  </div>
                  <div class="vcf-input">
                    <!-- <label for="from-datepicker" class="d-none">TT.MM.JJJJ</label> -->
                    <b-form-datepicker
                      class="vcs-date-box"
                      size="sm"
                      :locale="get_language()"
                      :max="maxDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="fromDate"
                    ></b-form-datepicker>
                  </div>
                  <!-- <input type="text" value="" placeholder="" name="from-datepicker" id="from-datepicker" class="d-none"> -->
                </div>

                <div class="vcs-check-filter">
                  <div class="vcf-title input-set">
                    <span class="cft-title">{{ labels.To }}</span>
                  </div>
                  <div class="vcf-input">
                    <!-- <label for="to-datepicker" class="d-none">TT.MM.JJJJ</label> -->
                    <b-form-datepicker
                      class="vcs-date-box"
                      size="sm"
                      :locale="get_language()"
                      :min="minDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="toDate"
                    ></b-form-datepicker>
                  </div>
                  <!-- <input type="text" value="" placeholder="" name="to-datepicker" id="to-datepicker" class="d-none"> -->
                </div>
              </div>
              <div class="vcs-column col-md-3 filter-section pb-2">
                <div class="vcs-filter-section">
                  <span v-for="(item, index) in selectedItem" :key="index">
                    <span class="vfi-link" v-if="!('AddressId' in item)"
                      ><span
                        class="fil-mark"
                        @click="removeSelectedItem(item, index)"
                        >x</span
                      >&nbsp;{{
                        item.Name
                          ? item.Name
                          : `${get_formatted_date(
                              item.from,
                              "DD.MM.YYYY"
                            )} - ${get_formatted_date(item.to, "DD.MM.YYYY")}`
                      }}</span
                    >
                    <span class="vfi-link" v-if="'AddressId' in item">
                      <div>
                        <span
                          class="fil-mark"
                          @click="removeSelectedItem(item, index)"
                          >x</span
                        >
                        <span
                          class="vfi-type"
                          v-if="
                            item.AddressId && (item.Street || item.StreetNumber)
                          "
                          >{{ item.Street }}, {{ item.StreetNumber }}
                        </span>
                      </div>
                      <div
                        class="vfi-address"
                        v-if="item.AddressId && (item.PostalCode || item.City)"
                      >
                        {{ item.PostalCode }},{{ item.City }}
                      </div>
                    </span>
                  </span>
                </div>
                <!-- clear filter icon -->
                <div>
                  <div
                    class="vcs-column address text-right pb-2 pr-2 ClearButton"
                    @click="clearFilter"
                  >
                    <IconSet icon="filter"></IconSet>{{ labels.RemoveFilter }}
                  </div>
                  <!-- <b-button
                    variant="primary"
                    class="w-100 p-2 rounded vgs-form-button filter-button"
                    @click="onFilterHandler"
                    >{{ labels.FilterBtnText }}
                  </b-button> -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:info>
          <div class="vcs-filter-info">
            <span class="vfi-title">{{ labels.SelectedFilters }}:</span>
            <span v-for="(item, index) in selectedItem" :key="index">
              <span class="vfi-link" v-if="!('AddressId' in item)"
                ><span class="fil-mark" @click="removeSelectedItem(item, index)"
                  >x</span
                >&nbsp;{{
                  item.Name
                    ? item.Name
                    : `${get_formatted_date(
                        item.from,
                        "DD.MM.YYYY"
                      )} - ${get_formatted_date(item.to, "DD.MM.YYYY")}`
                }}</span
              >
              <span class="vfi-link" v-if="'AddressId' in item">
                <span class="fil-mark" @click="removeSelectedItem(item, index)"
                  >x</span
                >
                <span
                  class="vfi-type"
                  v-if="item.AddressId && (item.Street || item.StreetNumber)"
                  ><img
                    :src="
                      item.Source.Emblem === ''
                        ? get_asset_image('emblems/villach.png')
                        : get_asset_image(`emblems/${item.Source.Emblem}`)
                    "
                    :alt="item.Source.Emblem"
                /></span>
                <span
                  class="vfi-type"
                  v-if="item.AddressId && (item.Street || item.StreetNumber)"
                  >{{ item.Street }}, {{ item.StreetNumber }}</span
                >
              </span>
            </span>
          </div>
        </template>
      </Toggle>
    </div>
    <div class="container1 mobileViewToggle">
      <div class="filterRow">
        <div class="vcs-right graph-right" v-if="isMobile">
          <h3>{{ labels.CurrentBalance }}</h3>
          <div class="vcs-account-state">
            <span v-if="account.Balance < 0">{{ labels.Deposit }}</span>
            <span v-else>{{ labels.Arrearage }}</span>
            <div
              class="vas-amount"
              v-html="get_curreny_formatted_number(account.Balance)"
            ></div>
          </div>

          <ChartDoughnut
            ref="booking_chart"
            :styles="style"
            :chart-data="chartData"
            aria-label=""
            class="vcs-chart"
            v-if="chartLoaded"
            :chartdata="chartData"
            :options="chartOptions"
            :width="chartWidth"
            :height="chartHeight"
          ></ChartDoughnut>
          <div class="graph-text-center">
            <p class="text-center p-0 mb-2 vcs-text text-lowercase">
              {{ labels.Spent }}
            </p>
            <p class="text-center p-0 mb-2 vcs-text">
              {{ get_formatted_date(account.From, "DD.MM.YYYY") }} -
              {{ get_formatted_date(account.To, "DD.MM.YYYY") }}
            </p>
            <h4
              class="text-center p-0 m-0 vcs-sum"
              v-html="get_curreny_formatted_number(account.Sum)"
            ></h4>
          </div>
          <div class="vcs-legend">
            <div
              v-for="(item, index) in this.legendCollection"
              :key="'lc' + index"
              class="csl-item"
              :style="'border-color: ' + item.Color + ';'"
            >
              <div
                :class="
                  selectedServiceGroupType != item.UUID &&
                  selectedServiceGroupType
                    ? 'graph-filter-deactivate-icon csl-item'
                    : 'csl-item'
                "
                :style="'border-color: ' + item.Color + ';'"
                @click="onClickLegend(item)"
              >
                <IconSet
                  :icon="item && item.Icon"
                  :title="item.Label"
                  class="sli-img"
                  :fill="item.Color"
                ></IconSet>
              </div>
            </div>
          </div>
        </div>
        <div class="vcs-left" v-if="isMobile">
          <div>
            <h3 class="mb-2 text-left bookingListHeading">
              {{ labels.Bookings }}
            </h3>
            <div class="vcs-export" style="display:none">
              <IconSet icon="csv" class="csv-file-icon"></IconSet>
              {{ labels.Export }}
            </div>
          </div>

          <BookingListToggle
            v-for="(item, index) in this.bookingList"
            :key="'bc' + index"
            mode="line"
            :id="'id_' + index"
            group="accounting"
            css="box"
            btnicon="info"
          >
            <template v-slot:title>
              <div class="threeInOne">
                <div class="">
                  <img
                    v-bind:src="
                      get_asset_image(
                        item.ServiceType && item.ServiceType.Source.Emblem
                          ? 'emblems/' + item.ServiceType.Source.Emblem
                          : 'emblems/villach.png'
                      )
                    "
                    :alt="labels.Emblem + ' ' + item.Name"
                  />
                </div>
                <div class="">
                  <IconSet
                    :icon="
                      item && item.ServiceTypeGroup.Icon
                        ? item.ServiceTypeGroup.Icon
                        : 'info'
                    "
                  ></IconSet>
                </div>
                <div class="">
                  <div class="vcs-title">
                    {{
                      item.ObjectName
                        ? item.ObjectName
                        : item.ServiceType.NameSpace
                    }}
                  </div>
                  <div class="vcs-type">{{ item.ServiceTypeGroup.Name }}</div>
                </div>
              </div>
              <div
                class="vcs-amount mobileHide"
                :class="item.State"
                v-html="
                  labels.Sum +
                    ' ' +
                    get_curreny_formatted_number(item.InvoiceAmount)
                "
              ></div>
              <div
                class="vcs-amount"
                :class="item.State"
                v-html="
                  labels.Balance +
                    ' ' +
                    get_curreny_formatted_number(item.AmountPending)
                "
              ></div>
            </template>
            <template v-slot:content class="booking-content">
              <div class="filterRow" v-if="!isMobile">
                <div class="col-md-5 mb-2 vcs-booking-line">
                  <b>{{ labels.BookingHeaderText }}</b>
                </div>
                <div class="col-md-3 mb-2 vcs-booking-line">
                  <b>{{ labels.Date }}</b>
                </div>
                <div class="col-md-3 mb-2 vcs-booking-line">
                  <b>{{ labels.Sum }}</b>
                </div>
                <div class="col-md-3 mb-2 vcs-booking-line">
                  <b>{{ labels.Balance }}</b>
                </div>
              </div>
              <div v-if="item.InvoiceAmount === 0">
                <div class="filterRow">
                  <div class="col-md-12 mb-2 vcs-booking-line">
                    <div class="text-center">
                      <b>{{ labels.NoDataFound }}</b>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="item.InvoiceAmount !== 0"
                class="booking-box booking-mobile"
              >
                <!-- eslint-disable -->
                <div
                  class="booking-section"
                  v-for="(booking, index) in item.Bookings"
                  :key="index"
                  v-if="booking.TargetActualTypeName === 'Target'"
                >
                  <div class="filterRow booking-row" v-if="!isMobile">
                    <div class="col-md-3 mb-2 vcs-booking-line">
                      <div class="right">
                        <span class="line2"
                          ><b>{{ booking.Line1 }}</b></span
                        ><br />
                        <span class="line2">{{ booking.Line2 }}</span>
                      </div>
                    </div>
                    <div class="col-md-3 mb-2 vcs-booking-line">
                      <div class="right">
                        {{
                          get_formatted_date(booking.BookingDate, "DD.MM.YYYY")
                        }}
                      </div>
                    </div>
                    <div class="col-md-3 mb-2 vcs-booking-line">
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount
                          )
                        "
                      ></div>
                    </div>
                    <div class="col-md-3 mb-2 vcs-booking-line">
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount - booking.BookingPaidAmount
                          )
                        "
                      ></div>
                    </div>
                  </div>

                  <!-- on mobile -->
                  <div
                    class="filterRow booking-description pt-2 mb-2"
                    v-if="isMobile"
                  >
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.BookingHeaderText }}</b>
                      </div>
                      <div class="right bookings-lines">
                        <span class="line1"
                          ><b>{{ booking.Line1 }}</b></span
                        ><br />
                        <span class="line2">{{ booking.Line2 }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.Date }}</b>
                      </div>
                      <div class="right">
                        {{
                          get_formatted_date(booking.BookingDate, "DD.MM.YYYY")
                        }}
                      </div>
                    </div>
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.Sum }}</b>
                      </div>
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount
                          )
                        "
                      ></div>
                    </div>
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.Balance }}</b>
                      </div>
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount - booking.BookingPaidAmount
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <!-- eslint-enable -->
              </div>
            </template>
          </BookingListToggle>
          <div class="empty-vcs-left-mobile" v-if="!this.bookingList.length">
            <p>
              <b>{{ labels.NoBooking }}</b>
            </p>
          </div>
        </div>

        <div class="vcs-left" v-if="!isMobile">
          <div>
            <h3 class="mb-2 text-left bookingListHeading">
              {{ labels.Bookings }}
            </h3>
            <div class="vcs-export" style="display:none">
              <IconSet icon="csv" class="csv-file-icon"></IconSet>
              {{ labels.Export }}
            </div>
          </div>

          <BookingListToggle
            v-for="(item, index) in this.bookingList"
            :key="'bc' + index"
            mode="line"
            :id="'id_' + index"
            group="accounting"
            css="box"
            btnicon="info"
          >
            <template v-slot:title>
              <div class="threeInOne">
                <div class="">
                  <img
                    :src="
                      get_asset_image(
                        item.ServiceType && item.ServiceType.Source.Emblem
                          ? 'emblems/' + item.ServiceType.Source.Emblem
                          : 'emblems/villach.png'
                      )
                    "
                    :alt="labels.Emblem + ' ' + item.Name"
                  />
                </div>
                <div class="">
                  <IconSet
                    :icon="
                      item && item.ServiceTypeGroup.Icon
                        ? item.ServiceTypeGroup.Icon
                        : 'info'
                    "
                  ></IconSet>
                </div>
                <div class="">
                  <div class="vcs-title">
                    {{
                      item.ObjectName
                        ? item.ObjectName
                        : item.ServiceType.NameSpace
                    }}
                  </div>
                  <div class="vcs-type">{{ item.ServiceTypeGroup.Name }}</div>
                </div>
              </div>
              <div
                class="vcs-amount mobileHide"
                :class="item.State"
                v-html="
                  labels.Sum +
                    ' ' +
                    get_curreny_formatted_number(item.InvoiceAmount)
                "
              ></div>
              <div
                class="vcs-amount"
                :class="item.State"
                v-html="
                  labels.Balance +
                    ' ' +
                    get_curreny_formatted_number(item.AmountPending)
                "
              ></div>
            </template>
            <template v-slot:content>
              <div
                class="filterRow"
                v-if="!isMobile && item.InvoiceAmount !== 0"
              >
                <div class="col-md-5 mb-2 vcs-booking-line">
                  <b>{{ labels.BookingHeaderText }}</b>
                </div>
                <div class="col-md-3 mb-2 vcs-booking-line">
                  <b>{{ labels.Date }}</b>
                </div>
                <div class="col-md-2 mb-2 vcs-booking-line">
                  <b>{{ labels.Sum }}</b>
                </div>
                <div class="col-md-2 mb-2 vcs-booking-line">
                  <b>{{ labels.Balance }}</b>
                </div>
              </div>
              <div v-if="item.InvoiceAmount === 0">
                <div class="filterRow">
                  <div class="col-md-12 mb-2 vcs-booking-line">
                    <div class="text-center">
                      <b>{{ labels.NoDataFound }}</b>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="item.InvoiceAmount !== 0"
                class="booking-box booking-table"
              >
                <!-- eslint-disable -->
                <div
                  v-for="(booking, index) in item.Bookings"
                  class="booking-section pt-2"
                  :key="index"
                  v-if="booking.TargetActualTypeName === 'Target' && !isMobile"
                >
                  <div class="filterRow booking-row">
                    <div class="col-md-5 mb-2 vcs-booking-line">
                      <div class="right">
                        <span class="line1"
                          ><b>{{ booking.Line1 }}</b></span
                        >
                        <span class="line2">{{ booking.Line2 }}</span>
                      </div>
                    </div>
                    <div class="col-md-3 mb-2 vcs-booking-line">
                      <div class="right">
                        {{
                          get_formatted_date(booking.BookingDate, "DD.MM.YYYY")
                        }}
                      </div>
                    </div>
                    <div class="col-md-2 mb-2 vcs-booking-line">
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount
                          )
                        "
                      ></div>
                    </div>
                    <div class="col-md-2 mb-2 vcs-booking-line">
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount - booking.BookingPaidAmount
                          )
                        "
                      ></div>
                    </div>
                  </div>

                  <!-- on mobile -->
                  <div
                    class="filterRow booking-description"
                    v-if="booking.TargetActualTypeName === 'Target' && isMobile"
                  >
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left"><b></b></div>
                      <div class="right bookings-lines">
                        <span class="line1"
                          ><b>{{ booking.Line1 }}</b></span
                        >
                        <span class="line2">{{ booking.Line2 }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.Date }}</b>
                      </div>
                      <div class="right">
                        {{
                          get_formatted_date(booking.BookingDate, "DD.MM.YYYY")
                        }}
                      </div>
                    </div>
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.Sum }}</b>
                      </div>
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount
                          )
                        "
                      ></div>
                    </div>
                    <div class="col-md-12 mb-2 vcs-booking-line">
                      <div class="left">
                        <b>{{ labels.Balance }}</b>
                      </div>
                      <div
                        class="right"
                        v-html="
                          get_curreny_formatted_number(
                            booking.BookingInvoiceAmount - booking.BookingPaidAmount
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- eslint-enable -->
            </template>
          </BookingListToggle>
          <div class="empty-vcs-left" v-if="!this.bookingList.length">
            <p>
              <b>{{ labels.NoBooking }}</b>
            </p>
          </div>
        </div>
        <div class="vcs-right graph-right" v-if="!isMobile">
          <h3>{{ labels.CurrentBalance }}</h3>

          <div class="vcs-account-state">
            <span v-if="account.Balance < 0">{{ labels.Deposit }}</span>
            <span v-else>{{ labels.Arrearage }}</span>
            <div
              class="vas-amount"
              v-html="get_curreny_formatted_number(account.Balance)"
            ></div>
          </div>

          <ChartDoughnut
            ref="booking_chart"
            :styles="style"
            :chart-data="chartData"
            aria-label=""
            class="vcs-chart"
            v-if="chartLoaded"
            :options="chartOptions"
            :width="chartWidth"
            :height="chartHeight"
          ></ChartDoughnut>
          <div class="graph-text-center">
            <p class="text-center p-0 mb-2 vcs-text text-lowercase">
              {{ labels.Spent }}
            </p>
            <p class="text-center p-0 mb-2 vcs-text" v-if="isDefaultDate">
              {{ get_formatted_date(defaultSelectedDate.From, "DD.MM.YYYY") }} -
              {{ get_formatted_date(defaultSelectedDate.To, "DD.MM.YYYY") }}
            </p>
            <p class="text-center p-0 mb-2 vcs-text" v-if="!isDefaultDate">
              {{ get_formatted_date(account.From, "DD.MM.YYYY") }} -
              {{ get_formatted_date(account.To, "DD.MM.YYYY") }}
            </p>
            <h4
              class="text-center p-0 m-0 vcs-sum"
              v-html="get_curreny_formatted_number(account.Sum)"
            ></h4>
          </div>
          <div class="vcs-legend">
            <div
              class="legendCollection"
              v-for="(item, index) in this.legendCollection"
              :key="'lc' + index"
            >
              <div
                :class="
                  selectedServiceGroupType != item.UUID &&
                  selectedServiceGroupType
                    ? 'graph-filter-deactivate-icon csl-item'
                    : 'csl-item'
                "
                :style="'border-color: ' + item.Color + ';'"
                @click="onClickLegend(item)"
              >
                <IconSet
                  :icon="item && item.Icon"
                  :title="item.Label"
                  class="sli-img"
                  :fill="item.Color"
                ></IconSet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
// import Vue from 'vue'
import Page from "@/structure/Page.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import BookingListToggle from "@/structure/BookingListToggle.vue";
import Toggle from "@/structure/Toggle.vue";
import ToggleMobile from "@/components/ToggleMobile.vue";
import ToggleFilterButton from "@/components/ToggleFilterButton.vue";
import IconSet from "@/structure/IconSet.vue";
import ChartDoughnut from "@/components/ChartDoughnut.vue";
import moment from "moment";
import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
import { mapActions } from "vuex";

export default {
  mixins: [vcm, vvm],
  name: "Accounting",
  components: {
    Page,
    ViewTitle,
    ChartDoughnut,
    IconSet,
    BookingListToggle,
    Toggle,
    ToggleMobile,
    ToggleFilterButton,
  },
  data() {
    return {
      labels: {
        AccountStatement: this.get_scope_translation(
          "AccountStatement",
          "label"
        ),
        Bookings: this.get_scope_translation("Bookings", "label"),
        Arrearage: this.get_scope_translation("Arrearage", "label"),
        Deposit: this.get_scope_translation("Deposit", "label"),
        CurrentBalance: this.get_scope_translation("CurrentBalance", "label"),
        Export: this.get_scope_translation("Export", "label"),
        Spent: this.get_scope_translation("Spent", "label"),
        ShowFilter: this.get_scope_translation("ShowFilter", "label"),
        HideFilter: this.get_scope_translation("HideFilter", "label"),
        Addresses: this.get_scope_translation("Addresses", "label"),
        Groups: this.get_scope_translation("Groups", "label"),
        Timespan: this.get_scope_translation("Timespan", "label"),
        RemoveAllFilters: this.get_scope_translation(
          "RemoveAllFilters",
          "label"
        ),
        SelectedFilters: this.get_scope_translation("SelectedFilters", "label"),
        Filter: this.get_scope_translation("Filter", "label"),
        From: this.get_scope_translation("From", "label"),
        To: this.get_scope_translation("To", "label"),
        Emblem: this.get_translation("App", "Emblem", "label"),
        Sum: this.get_translation("App", "Sum", "label"),
        FilterBtnText: this.get_scope_translation("ApplyFilter", "label"),
        RemoveFilter: this.get_scope_translation("Filter löSchen", "label"),
        NoBooking: this.get_scope_translation("NoBooking", "label"),
        NoDataFound: this.get_scope_translation("NoDataFound", "label"),
        Date: this.get_scope_translation("NoDataFound", "label"),
        Balance: this.get_scope_translation("NoDataFound", "label"),
        BookingHeaderText: "Buchungstext",
      },
      account: {
        Balance: 0,
        From: moment().format("YYYY-MM-DD"),
        To: moment().format("YYYY-MM-DD"),
        Sum: 0,
      },
      maxDate: null,
      minDate: null,
      isHover: false,
      allIsOpen: false,
      isMobile: false,
      isTrue: false,
      isDefaultDate: true,
      onLoadDate: false,
      isClearFilter: false,
      defaultSelectedDate: {
        From: "",
        To: "",
      },
      isMobileStreet: false,
      isMobileSerivceType: false,
      isMobileDate: false,
      islegendCollection: false,
      selectedItem: [],
      selectedDate: {},
      selectedAddress: [],
      legendCollection: [],
      selectedServiceGroupType: null,
      chartWidth: 280,
      chartHeight: 280,
      chartLoaded: false,
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 3,
            borderColor: [],
            backgroundColor: [],
            data: [],
            weight: 1,
          },
        ],
      },
      selectedLegend: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        cutoutPercentage: 80,
        weight: 1,
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]];
            },
            label: function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var currency = dataset["data"][
                tooltipItem["index"]
              ].toLocaleString("de-AT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
              });
              return currency;
            },
          },
        },
      }
    };
  },
  created: async function() {
    window.addEventListener("resize", this.windowResizeHandler);
    await this.getBookings();
  },
  async mounted() {
    this.windowResizeHandler();
    this.$root.set_document_title("route", "", "");
    const FiledLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "Accounting"
    );
    Object.assign(this.labels, FiledLabels);

    // calcualte min and max date
    this.onLoadDate = true;
    this.islegendCollection = true;
    setTimeout(() => this.addAriaLabel(), 1100);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResizeHandler);
  },

  computed: {
    style() {
      return {
        position: "relative",
      };
    },
    selectItem() {
      return JSON.parse(JSON.stringify(this.selectedItem));
    },
    bookingList() {
      return this.$store.state.booking.bookingList;
    },
    bookingCollection() {
      return this.$store.state.booking.bookingCollection;
    },
    addressList() {
      return this.$store.state.booking.bookingAddress;
    },
    serviceGroupCollection() {
      return this.$store.state.booking.serviceGroupCollections;
    },  
    selectedFilterItem: {
      get() {
        return this.$store.state.booking.selectedFilterItem;
      },
      set(item) {
        this.$store.commit("booking/SET_SELECTED_FILTER_ITEM", item);
      },
    },
    fromDate: {
      get() {
        return this.account.From;
      },
      set(value) {
        this.account.From = value;
        let tmpDate = value.split("-");
        this.minDate = new Date(tmpDate[0], tmpDate[1] - 1, tmpDate[2]);
        this.isDefaultDate = false;
        this.dateSelectFilter();
        this.onFilterHandler();
      },
    },
    toDate: {
      get() {
        return this.account.To;
      },
      set(value) {
        this.account.To = value;
        let tmpDate = value.split("-");
        this.maxDate = new Date(tmpDate[0], tmpDate[1] - 1, tmpDate[2]);
        this.isDefaultDate = false;
        this.dateSelectFilter();
        this.onFilterHandler();
      },
    },
  },
  watch: {
    bookingList: {
      handler: function(newVal) {
        if (!this.serviceGroupCollection.length) {
          this.EmptyGraphWithoutServiceGroup();
          return;
        }
        if (!newVal.length) {
          this.EmptyAccountingGraph();
        } else {
          this.makeAccoundingGraph();
        }
      },
    },
    bookingCollection: {
      handler: function(newVal) {
        if (newVal.length !== 0) {
          this.filterDateRange(newVal);
          this.addresswithBookingAddress();
        }
      },
    },
    selectedItem: {
      handler: async function(newVal) {
        let serviceGroupType = this.selectedServiceGroupType ? true : false;
        this.selectedServiceGroupType = !newVal.length
          ? null
          : this.selectedServiceGroupType;
        this.islegendCollection = false;
        if (this.isClearFilter) {
          this.isClearFilter = false;
          return;
        }
        this.isMobileStreet = false;
        this.isMobileSerivceType = false;
        this.isMobileDate = false;
        let counter = 0;
        for (let i = 0; i < newVal.length; i++) {
          if ("AddressId" in newVal[i]) {
            this.isMobileStreet = true;
          }
          if ("Name" in newVal[i]) {
            counter += 1;
            this.isMobileSerivceType = true;
            if (!serviceGroupType && counter === 1) {
              this.selectedServiceGroupType = this.selectedServiceGroupType
                ? null
                : newVal[i].UUID;
            } else {
              this.selectedServiceGroupType = null;
            }
          }
          if ("from" in newVal[i]) {
            this.isMobileDate = true;
          }
        }
        this.onFilterHandler();
      },
    },
  },

  methods: {
    ...mapActions({
      getBookings: "booking/getBooking",
    }),
    addAriaLabel: function() {
      var buttons = document.getElementsByTagName("button");
      if (typeof buttons != "undefined" && buttons.length > 0) {
        Array.from(buttons).forEach((v) => {
          if (!v.attributes["aria-label"]) {
            v.setAttribute("aria-label", "Auswählen");
          }
        });
      }
    },
    filterDateRange(bookingCollection = this.bookingCollection) {
      if (Array.isArray(bookingCollection) && bookingCollection.length) {
        let fromDateArr = new Set();
        for (let date of bookingCollection) {
          for (let booking of date.Bookings) {
            fromDateArr.add(booking.BookingDate);
          }
        }
        this.getDateRange(fromDateArr);
      }
    },
    onClickLegend(item) {
      this.isClearFilter = true;
      this.islegendCollection = false;
      this.selectedItem = [];
      this.selectedDate = {};
      this.filterDateRange();
      if (this.selectedServiceGroupType === item.UUID) {
        this.selectedServiceGroupType = null;
        this.$store.commit("booking/SET_LEGEND_BOOKING_LIST", false);
      } else {
        this.selectedServiceGroupType = item.UUID;
        this.selectedItem = this.serviceGroupCollection.filter(
          (ServiceTypeGroup) => ServiceTypeGroup.UUID === item.UUID
        );
        this.$store.commit("booking/SET_LEGEND_BOOKING_LIST", item);
      }
    },
    removeSelectedItem(item, index) {
      if ("from" in item) {
        this.selectedDate = {};
        this.isDefaultDate = true;
        this.filterDateRange();
      }
      if ("Name" in item) {
        this.selectedServiceGroupType = null;
      }
      this.selectedItem.splice(index, 1);
      this.onFilterHandler();
    },
    addresswithBookingAddress() {
      let addressIdObj = {};
      let addressIdWithBookingObj = {};
      let matchBookingAddress = {};
      for (let address of this.addressList) {
        addressIdObj[address.AddressId] = address;
      }
      for (let bookingObj of this.bookingList) {
        for (let booking of bookingObj.Bookings) {
          addressIdWithBookingObj[booking.Address.AddressId] = booking.Address;
        }
      }

      for (let address in addressIdObj) {
        if (addressIdWithBookingObj[address]) {
          matchBookingAddress[address] = addressIdWithBookingObj[address];
        }
      }
      for (let address of this.addressList) {
        address.Bookings = [];
        if (matchBookingAddress[address.AddressId]) {
          address.Bookings.push(matchBookingAddress[address.AddressId]);
        }
      }
    },

    windowResizeHandler() {
      // your code for handling resize...
      this.isMobile = window.innerWidth < 800 ? true : false;
    },
    allOpenHandler() {
      this.allIsOpen = !this.allIsOpen;
    },
    updateChart() {
      this.$nextTick(() => {
        let self = this;
        self.$refs.booking_chart && this;
        self.$refs.booking_chart.update();
      });
    },

    // make graph accouding to booking Data
    makeAccoundingGraph() {
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
      this.account.Sum = 0;
      this.account.Balance = 0;
      let actualAmount = 0;
      let targetAmount = 0;
      var cc = 0;
      for (var i = 0; i < this.serviceGroupCollection.length; i++) {
        var sgItem = this.serviceGroupCollection[i];
        var sgBookings = this.get_bookings_by_service_group(sgItem.UUID);
        var bc = 0;
        var totalInvoiceAmount = 0;
        var totalActualAmount = 0;

        for (var j = 0; j < sgBookings.length; j++) {
          var bgItem = sgBookings[j];
          totalInvoiceAmount += bgItem.InvoiceAmount;
          totalActualAmount += bgItem.InvoiceAmount - bgItem.AmountPending;

          bc++;
        }

        targetAmount += totalInvoiceAmount;
        actualAmount += totalActualAmount;

        if (bc > 0) {
          this.chartData.labels.push(sgItem.Name);
          this.chartData.datasets[0].backgroundColor.push(sgItem.Color);
          this.chartData.datasets[0].data.push(totalInvoiceAmount);
          if (this.islegendCollection) {
            this.legendCollection.push({
              ID: cc,
              UUID: sgItem.UUID,
              Label: sgItem.Name,
              Icon: sgItem.Icon,
              Color: sgItem.Color,
              Sum: totalInvoiceAmount,
            });
          }
          cc++;
        }
      }

      this.account.Sum = targetAmount;
      this.account.Balance = targetAmount - actualAmount;
      if (!targetAmount) {
        this.EmptyAccountingGraph();
      }
      this.updateChart();
      if (cc) {
        this.chartLoaded = true;
      }
    },

    get_bookings_by_service_group: function(UUID) {
      return this.bookingList.filter(
        (obj) => obj.ServiceTypeGroup.UUID === UUID
      );
    },

    // get_bookings_by_service_group_for_empty: function(uuid) {
    //   return this.bookingCollection.filter(function(obj) {
    //     return obj.ServiceType.uuid == uuid;
    //   });
    // },

    getDateRange(fromDates) {
      let dates = [...fromDates];
      dates = dates.map((date) => new Date(date));
      var max = new Date(Math.max.apply(null, dates));
      var min = new Date(Math.min.apply(null, dates));
      this.defaultSelectedDate.From = min;
      this.defaultSelectedDate.To = max;
      if (this.onLoadDate) {
        this.account.From = min;
        this.account.To = max;
        this.minDate = min;
        this.maxDate = max;
      }
    },

    EmptyAccountingGraph() {
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
      this.legendCollection = [];
      var cc = 0;
      this.account.Balance = 0;
      this.account.Sum = 0;
      for (let serviceType of this.serviceGroupCollection) {
        var bc = 0;
        bc++;
        if (bc > 0) {
          this.chartData.labels.push(serviceType.Name);
          this.chartData.datasets[0].backgroundColor.push(serviceType.Color);
          this.chartData.datasets[0].data.push(0.00001);
          cc++;
        }
      }
      this.updateChart();
      if (cc) {
        this.chartLoaded = true;
      }
    },

    EmptyGraphWithoutServiceGroup() {
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
      this.legendCollection = [];
      var cc = 0;
      this.account.Balance = 0;
      this.account.Sum = 0;
      // eslint-disable-next-line
      for (let key of [1]) {
        var bc = 0;
        bc++;
        if (bc > 0) {
          this.chartData.labels.push("");
          this.chartData.datasets[0].backgroundColor.push("#808080");
          this.chartData.datasets[0].data.push(0.00001);
          cc++;
        }
      }
      this.updateChart();
      if (cc) {
        this.chartLoaded = true;
      }
    },

    clearFilter() {
      this.isClearFilter = true;
      this.selectedItem = [];
      this.selectedDate = {};
      this.isDefaultDate = true;
      this.isMobileStreet = false;
      this.isMobileSerivceType = false;
      this.isMobileDate = false;
      this.filterDateRange();
      this.getBookings();
    },

    onFilterHandler() {
      if (!this.selectedItem.length) {
        this.clearFilter();
        return;
      }

      let filterObj = {
        addresses: [],
        serviceGroups: [],
        date: [],
      };

      let selectBookingArr = [];
      let isSelectedAddress = false;
      let isSelectedServiceGroupType = false;
      let isSelectedDate = false;
      this.islegendCollection = false;
      for (let item of this.selectedItem) {
        if ("AddressId" in item) {
          filterObj.addresses.push(item);
          isSelectedAddress = true;
        }
        if ("Name" in item) {
          filterObj.serviceGroups.push(item);
          isSelectedServiceGroupType = true;
        }
        if ("from" in item) {
          filterObj.date.push(item);
          this.defaultSelectedDate = {
            From: item.from,
            To: item.to,
          };
          isSelectedDate = true;
        }
      }

      // 1 active
      if (isSelectedAddress && !isSelectedServiceGroupType && !isSelectedDate) {
        for (let booking of this.bookingCollection) {
          let bookingArr = booking.Bookings.map(
            (booking) => booking.Address.AddressId
          );
          booking.uniqueBookingIdArr = [...new Set(bookingArr)];
        }

        for (let bookingObj of this.bookingCollection) {
          for (let address of filterObj.addresses) {
            if (bookingObj.uniqueBookingIdArr.includes(address.AddressId)) {
              selectBookingArr.push(bookingObj);
            }
          }
        }
      }

      // 2 active
      if (!isSelectedAddress && isSelectedServiceGroupType && !isSelectedDate) {
        this.bookingCollection.map((selectBooking) => {
          filterObj.serviceGroups.filter((service) => {
            if (service.UUID === selectBooking.ServiceTypeGroup.UUID) {
              selectBookingArr.push(selectBooking);
            }
          });
        });
      }

      // 3 active
      if (!isSelectedAddress && !isSelectedServiceGroupType && isSelectedDate) {
        const cloneBookingCollection = JSON.parse(
          JSON.stringify(this.bookingCollection)
        );
        this.$store.commit(
          "booking/SET_DATE_BOOKING_LIST",
          cloneBookingCollection
        );
        return;
      }

      // 1-2 active
      if (isSelectedAddress && isSelectedServiceGroupType && !isSelectedDate) {
        for (let booking of this.bookingCollection) {
          let bookingArr = booking.Bookings.map(
            (booking) => booking.Address.AddressId
          );
          booking.uniqueBookingIdArr = [...new Set(bookingArr)];
        }

        for (let bookingObj of this.bookingCollection) {
          for (let address of filterObj.addresses) {
            if (bookingObj.uniqueBookingIdArr.includes(address.AddressId)) {
              selectBookingArr.push(bookingObj);
            }
          }
        }

        let copySelectBookingArr = JSON.parse(JSON.stringify(selectBookingArr));
        selectBookingArr = [];
        for (let selectBooking of copySelectBookingArr) {
          filterObj.serviceGroups.filter((service) => {
            if (service.UUID === selectBooking.ServiceTypeGroup.UUID) {
              selectBookingArr.push(selectBooking);
            }
          });
        }
      }

      // 1-2-3 active
      if (isSelectedAddress && isSelectedServiceGroupType && isSelectedDate) {
        for (let booking of this.bookingCollection) {
          let bookingArr = booking.Bookings.map(
            (booking) => booking.Address.AddressId
          );
          booking.uniqueBookingIdArr = [...new Set(bookingArr)];
        }

        for (let bookingObj of this.bookingCollection) {
          for (let address of filterObj.addresses) {
            if (bookingObj.uniqueBookingIdArr.includes(address.AddressId)) {
              selectBookingArr.push(bookingObj);
            }
          }
        }

        let copySelectBookingArr = JSON.parse(JSON.stringify(selectBookingArr));
        selectBookingArr = [];
        for (let selectBooking of copySelectBookingArr) {
          filterObj.serviceGroups.filter((service) => {
            if (service.UUID === selectBooking.ServiceTypeGroup.UUID) {
              selectBookingArr.push(selectBooking);
            }
          });
        }
        let cloneSelectBookingArr = JSON.parse(
          JSON.stringify(selectBookingArr)
        );
        this.$store.commit(
          "booking/SET_DATE_BOOKING_LIST",
          cloneSelectBookingArr
        );
        return;
      }

      // 2-3 active
      if (!isSelectedAddress && isSelectedServiceGroupType && isSelectedDate) {
        for (let booking of this.bookingCollection) {
          filterObj.serviceGroups.filter((service) => {
            if (service.UUID === booking.ServiceTypeGroup.UUID) {
              selectBookingArr.push(booking);
            }
          });
        }
        let cloneSelectBookingArr = JSON.parse(
          JSON.stringify(selectBookingArr)
        );
        this.$store.commit(
          "booking/SET_DATE_BOOKING_LIST",
          cloneSelectBookingArr
        );
        return;
      }

      // 1-3 active
      if (isSelectedAddress && !isSelectedServiceGroupType && isSelectedDate) {
        for (let booking of this.bookingCollection) {
          let bookingArr = booking.Bookings.map(
            (booking) => booking.Address.AddressId
          );
          booking.uniqueBookingIdArr = [...new Set(bookingArr)];
        }
        for (let booking of this.bookingCollection) {
          filterObj.addresses.filter((address) => {
            if (booking.uniqueBookingIdArr.includes(address.AddressId)) {
              selectBookingArr.push(booking);
            }
          });
        }
        let cloneSelectBookingArr = JSON.parse(
          JSON.stringify(selectBookingArr)
        );
        this.$store.commit(
          "booking/SET_DATE_BOOKING_LIST",
          cloneSelectBookingArr
        );
        return;
      }
      this.$store.commit("booking/SET_BOOKING_LIST", selectBookingArr);
    },

    dateSelectFilter() {
      this.islegendCollection = false;
      if (!this.selectedItem.length || !this.selectedDate.from) {
        this.selectedDate = {
          from: moment(this.account.From).format("YYYY-MM-DD"),
          to: moment(this.account.To).format("YYYY-MM-DD"),
        };
        this.selectedItem = [...this.selectedItem, this.selectedDate];
        this.$store.commit("booking/SET_BOOKING_DATE", this.selectedDate);
      } else {
        for (let selectItem of this.selectedItem) {
          if (selectItem.from) {
            selectItem.from = this.account.From;
            selectItem.to = this.account.To;
            this.$store.commit("booking/SET_BOOKING_DATE", selectItem);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";

.vcs-date-box {
  width: 130px;
}
.vcs-check-filter {
  margin-top: 6px;
  margin-bottom: 24px;
  margin-left: 6px;
}
.vcs-check-filter:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.vcs-check-filter .vcf-box {
  position: relative;
  float: left;
  width: 18px;
  height: 18px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  margin-top: 6px;
  margin-right: 10px;
  padding: 0;
}
.vcs-check-filter .vcf-box .cfb-tray {
  position: absolute;
  top: -8px;
  left: -7px;
}
.vcs-check-filter .vcf-box .cfb-tray .b-icon {
  color: $highlightFontColor;
  font-size: 2rem;
  margin: 0 0 0 0;
}
.vcs-check-filter .vcf-emblem {
  float: left;
  margin-right: 6px;
}
.vcs-check-filter .vcf-title {
  float: left;
  word-break: break-word;
}
.vcs-check-filter .vcf-title .cft-title {
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: 400;
  display: block;
  margin-top: 6px;
}
.vcs-check-filter .vcf-title .cft-name {
  font-size: 0.813rem;
  line-height: 1rem;
  font-weight: 700;
  display: block;
}
.vcs-check-filter .vcf-title .cft-type {
  font-size: 0.813rem;
  line-height: 1rem;
  font-weight: 400;
  display: block;
}

.vcs-check-filter .vcf-title.input-set {
  min-width: 40px;
  margin-left: 14px;
}

.vcs-check-filter .vcf-input {
  float: left;
}

.vcs-booking-line {
  font-size: 0.875rem;
  line-height: 1rem;
}

.vcs-column.group {
  width: 22% !important;
}
.toggle-content.mobileFilter .vcs-column {
  width: 100% !important;
}
.vcs-column.timespan {
  width: 240px !important;
}
.vcs-column.filter {
  width: calc(100% - 660px) !important;
}

.vcs-column.highlight {
  border-top: 2px solid transparent;
}

.vcs-column.highlight:hover {
  border-top: 2px solid $highlightColor;
  background-color: #dfeaf0;
}

.vcs-column.line {
  border-right: 1px solid #ffffff;
}
.vcs-column .vgs-icon-set {
  margin-right: 6px;
  margin-left: 5px;
}
.filter-section {
  display: flex;
  flex-direction: column;
  width: 18rem;
  justify-content: space-between;
}

.filter-section .address {
  width: 100%;
}

div#checkbox-group .custom-control .custom-control-label {
  font-size: initial !important;
}

.filter-button:hover {
  font-size: inherit;
}

.vcs-filter-info {
  padding: 0.75rem;
  padding-left: 1.2rem;
}
.vcs-filter-info .vfi-title {
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: 700;
}
.vcs-filter-info .vfi-link {
  font-size: 0.875rem;
  line-height: 1.188rem;
  margin-left: 6px;
}
.vcs-filter-info .vfi-link .fil-mark {
  color: $highlightFontColor;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
}

.vcs-filter-section {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  padding-left: 1.2rem;
}
.vcs-filter-section .vfi-title {
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: 700;
}
.vcs-filter-section .vfi-link {
  margin-bottom: 0.875rem;
  font-size: 14px;
  line-height: 1.188rem;
}
.vcs-filter-section .vfi-link .fil-mark {
  color: $highlightFontColor;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
}

.col.UnPaid {
  color: $highlightFontColor;
}

.vcs-text {
  font-size: 0.875rem;
  line-height: 1.188rem;
}
// .vcs-title { font-size: 0.875rem; line-height: 1.2rem; }
.vcs-type {
  font-size: 0.75rem;
  line-height: 1rem;
}
.vcs-amount {
  font-size: 0.775rem;
  line-height: 1.2rem;
}
.vcs-sum {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.vcs-account-state {
  background-color: $highlightFontBGColor;
  color: $highlightFontColor;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  text-align: left;
}
.vcs-account-state .vas-amount {
  float: right;
  margin: 0 0.75rem 0 0;
}

.vcs-left {
  width: calc(100% - 300px);
  margin-right: 20px;
}
.vcs-right {
  text-align: center;
}
.graph-right {
  position: relative;
  // width: 28%;
}

.vcs-chart {
  margin: 2rem auto 0 auto;
}

.vcs-legend {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0 0 0;
  cursor: pointer;
}
.vcs-legend:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.vcs-legend .csl-item {
  float: left;
  display: table-cell;
  width: 22px;
  height: 35px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin: 0 6px 0 6px;
}
.vcs-legend .csl-item img.sli-img {
  width: 22px;
  vertical-align: bottom;
}
.vfi-type {
  font-weight: bold;
  margin-left: 0.25rem;
  word-break: break-word;
}
.vfi-address {
  padding-left: 12px;
}

.csv-file-icon {
  filter: invert(13%) sepia(91%) saturate(4054%) hue-rotate(334deg)
    brightness(93%) contrast(92%);
}

h2 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.vcs-export {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  text-align: right;
  // margin-top: -1.75rem;
  font-size: 0.875rem;
  line-height: 1.688rem;
  color: $highlightFontColor;
  padding-right: 0.3rem;
}

.mobileView .vcs-title {
  padding: 0;
}
h3 {
  width: 100%;
  text-align: right;
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.688rem;
  font-weight: 700;
  text-align: left;
}
.booking-description {
  display: flex;
  flex-direction: column;
}
.filterHeading .vcs-column {
  min-height: 51px;
  align-items: center;
  display: flex;
}

.bookingListHeading,
.toggle-handle {
  padding: 0px;
}
.bookingListHeading,
.filterHeading .vcs-column {
  font-size: 18px;
  cursor: pointer;
}
.timespan .cft-title {
  font-weight: bold !important;
}
button {
  font-size: 16px;
  padding: 0px !important;
  height: 45px;
}
.filter-header-text {
  display: flex;
  align-items: center;
}
.filter-header-text .vgs-icon-set {
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: 20px;
}
.filter-header-text > span {
  padding-left: 10px !important;
}
.filterr-top {
  justify-content: flex-end;
  align-items: center;
}
.bookingRowHeading .threeInOne {
  width: 55%;
  // word-break: break-all;
}
.booking-description .vcs-booking-line {
  display: flex;
  align-items: center;
}

.bookingRowHeading .threeInOne .vcs-type {
  font-size: 12px !important;
  color: #757575 !important;
}
.bookingRowHeading .threeInOne .vcs-title {
  color: #000000;
}

.onHoverColIcon {
  /*filter: invert(13%) sepia(91%) saturate(4054%) hue-rotate(334deg) brightness(93%) contrast(92%);*/
  fill: $primaryColor;
}
.onHoverColText {
  color: $primaryColor;
}

.graph-text-center {
  position: absolute;
  top: 13rem;
  right: 0;
  left: 0;
  z-index: -9999;
}

.empty-vcs-left {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
  text-align: center;
}
.empty-vcs-left-mobile {
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.ClearButton {
  cursor: pointer;
}

.vcs-filter-section span:not(:first-child),
.vcs-filter-section span:not(:last-child) {
  margin-bottom: 20px;
}

.booking-section:nth-child(even) {
  align-items: center;
  background-color: white;
}

.booking-row {
  align-items: center !important;
}

.line1 {
  height: 1.5rem;
}

.line2 {
  font-size: 12px;
  color: #6b6b6b;
}
.vcs-booking-line > .right {
  display: flex;
  flex-direction: column;
}

.graph-filter-deactivate-icon > .vgs-icon-set {
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
}
.graph-filter-deactivate-icon {
  border-color: gray !important;
}
.display-none-heading {
  display: none;
}
@media (max-width: 767px) {
  .line1 {
    height: 40px;
  }
  .vcs-booking-line > .right {
    display: block;
  }
  .mobileView .row > div {
    padding: 0 5px;
    max-width: 20%;
    flex: unset;
  }
  .mobileView .vcs-amount {
    font-size: 12px !important;
  }

  .toggle-tray {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vcs-right {
    width: 100%;
  }
  .vcs-column.timespan[data-v-7e0221cf] {
    width: 100% !important;
  }
  .vcs-left {
    width: 100%;
    margin-right: unset;
  }
  .vcs-column.filter {
    width: unset;
  }
  .vgs-toggle.box .toggle-title .toggle-handle {
    padding: unset;
  }
  .vcs-amount.mobileHide {
    display: none;
  }
  .vcs-booking-line > .left {
    min-width: 6.7rem;
  }
  .vfi-address {
    padding-left: 2.5rem;
  }
  // .vgs-toggle .toggle-title .toggle-handle { display: table-cell; width: 20%; text-align: center; }
}
@media (min-width: 768px) and (max-width: 991px) {
  .vcs-booking-line > .left {
    min-width: 7.7rem;
  }

  .line1 {
    height: 0.6rem;
  }

  .vcs-check-filter .vcf-title {
    width: max-content;
  }
  .toggle-content.mobileFilter .vcs-column {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 2500px) and (min-width: 1521px) {
  .graph-right {
    width: 28% !important;
  }
}
</style>
