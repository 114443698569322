<template>
   <div class="vgs-toggle" v-bind:class="get_class" data-allow-toggle>
        <ToggleTitle>
           <template slot="title">
            <div class="tgh-space"></div>
            <div class="filter-header" @click="isOpenHandler">
                <slot name="title" />
                <b-icon icon="chevron-up"   v-if="isOpen"></b-icon>
                <b-icon icon="chevron-down" v-if="!isOpen"></b-icon>
            </div>
          </template>
        </ToggleTitle>
        <div class="toggle-content mobileFilter fade-in" v-if="isOpen">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
import ToggleTitle from "./ToggleTitle";
import { vcm } from '@/mixins/vcm'

export default {
    mixins: [vcm],
    name: 'ToggleMobile',
    components: {
        ToggleTitle
    },
    props: {
        allIsOpen : Boolean
    },
    data() {
        return {
            toggleOpen: this.open,
            isOpen: false,
            labels: {
                Open: this.get_translation('App','Open','label'),
                Close: this.get_translation('App','Close','label'),
            }
        };
    },
    mounted() {
        
    },
    watch: {
    // whenever question changes, this function will run
    allIsOpen: function (newValue) {
      this.isOpen = newValue;
    }
   },
    computed: {
        get_class: function(){
            var rv = ' ' + this.mode + ' ' + this.css + ' '
            if (this.toggleOpen) {
                rv += ' open '
            }
            return rv
        },
        get_toggle_btn_aria: function(){
            if (this.toggleOpen) {
                return this.labels.Close
            } else {
                return this.labels.Open
            }
        },
    },

  
    methods: {
        isOpenHandler() {
            this.isOpen = !this.isOpen;
        },
        open() {
            console.log("open");
        },
         close() {
            console.log("close");
        },
        
        // toggleHandler(event) {
        //     console.log("event", event.target);
        // },
        toggle_content(){
            if (this.toggleOpen) {
                this.toggleOpen = false
            } else {
                this.toggleOpen = true
                this.$root.$emit('com_event_toggle_open_' + this.group,this.id)
            }
        },
        toggle_content_set(setState){
            this.toggleOpen = setState
        },
    }
}
</script>


<style scoped >
.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 19px 0px 15px;
    font-size:18px;
    min-height: 49px;
    border-bottom: 2px solid #fff;
} 

.toggle-content {
    background-color: #ebf0f4;
}
.fade { visibility: hidden; }
.mobileFilter, .mobileFilter .custom-control-label {
    padding: 0px !important;
}
.mobileFilter .vcs-check-filter, .mobileFilter .form-group {
    margin: 0px !important;
}
.toggle-content.mobileFilter {
    border-top: 0px !important;
}
</style>
