<template>
   <div class="vgs-toggle" :class="get_class" data-allow-toggle >
        <div class="toggle-title" @click="toggle_content()">
            <div class="toggle-tray filterRow pt-2 pb-2 booking-row">
                <div class="bookingRowHeading"><slot name="title" />
                <div class="toggle-handle text-right" v-if="btnhide !== true">
                    <div class="tgh-space"></div>
                    <span v-if="this.mode == 'head'" class="fade-in" >
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>

                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === true" icon="chevron-up"></b-icon>
                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === false" icon="chevron-down"></b-icon>

                        <IconSet v-if="this.btnicon !== '' && this.btniconclose === undefined" :icon="this.btnicon"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === true" :icon="this.btniconclose"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === false" :icon="this.btnicon"></IconSet>

                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>
                    </span>
                    <a v-else :aria-label="this.get_toggle_btn_aria" href="#" v-bind:aria-expanded="this.toggleOpen" class="pr-2" >
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === false && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>

                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === true" icon="chevron-up"></b-icon>
                        <b-icon v-if="this.btnicon === undefined && this.toggleOpen === false" icon="chevron-down"></b-icon>

                        <IconSet v-if="this.btnicon !== '' && this.btniconclose === undefined" :icon="this.btnicon"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === true" :icon="this.btniconclose"></IconSet>
                        <IconSet v-if="this.btnicon !== '' && this.btniconclose !== undefined && this.toggleOpen === false" :icon="this.btnicon"></IconSet>

                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose === undefined">{{ this.btnlabel }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === true">{{ this.btnlabelclose }}&nbsp;</span>
                        <span v-if="this.btnlabelright === true && this.btnlabel !== '' && this.btnlabelclose !== undefined && this.toggleOpen === false">{{ this.btnlabel }}&nbsp;</span>
                    </a>
                </div>
                </div>
            </div>
        </div>
        <div class="toggle-content fade-in booking-content">
            <slot name="content" />
        </div>
        <div v-if="this.activateinfo === true && this.toggleOpen === false" class="toggle-info">
            <slot name="info" />
        </div>
    </div>
</template>

<script>
import IconSet from '@/structure/IconSet.vue'
import { vcm } from '@/mixins/vcm'

export default {
    mixins: [vcm],
    name: 'Toggle',
    components: {
        IconSet
    },
    props: {
        mode: String,
        id: String,
        open: Boolean,
        group: String,
        btnlabel: String,
        btnlabelclose: String,
        css: String,
        btnicon: String,
        btniconclose: String,
        activateinfo: Boolean,
        btnlabelright: Boolean,
        btnhide: Boolean
    },
    data() {
        return {
            toggleOpen: this.open,
            labels: {
                Open: this.get_translation('App','Open','label'),
                Close: this.get_translation('App','Close','label'),
            }
        };
    },
    mounted() {
        this.$root.$on('com_event_toggle_open_' + this.group, (eventID) => {
            if (eventID !== this.id) {
                this.toggle_content_set(false)
            }
        })
    },
    computed: {
        get_class: function(){
            var rv = ' ' + this.mode + ' ' + this.css + ' '
            if (this.toggleOpen) {
                rv += ' open '
            }
            return rv
        },
        get_toggle_btn_aria: function(){
            if (this.toggleOpen) {
                return this.labels.Close
            } else {
                return this.labels.Open
            }
        },
    },
    methods: {
        toggle_content(){
            if (this.toggleOpen) {
                this.toggleOpen = false
            } else {
                this.toggleOpen = true
                this.$root.$emit('com_event_toggle_open_' + this.group,this.id)
            }
        },
        toggle_content_set(setState){
            this.toggleOpen = setState
        },
    }
}
</script>


<style scoped >

.fade { visibility: hidden; }
.booking-row {
    cursor: pointer;
}
.booking-content {
        overflow-y: scroll;
}
@media (max-width: 767px) { 
    .booking-content {
        max-height: 23rem;
    }
}
@media (max-width: 991px) and (min-width: 768px) { 
    .booking-content {
        max-height: 22rem;
    }
}

@media (max-width: 1200px) and (min-width: 992px) { 
    .booking-content {
        max-height: 20rem;
    }
}

@media (max-width: 1520px) and (min-width: 1200px) { 
    .booking-content {
        max-height: 27rem;
    }
}
@media (max-width: 2500px) and (min-width: 1521px) {
    .booking-content {
        max-height: 20.5rem;
    }
}

.booking-content::-webkit-scrollbar {
    width: 12px;
}
.booking-content::-webkit-scrollbar-thumb {
    background-color: #cddce3;
    border-radius: 20px;
    border: 3px solid #ebf0f4;
}
.booking-content::-webkit-scrollbar-track {
    background: #ebf0f4;
}

</style>
